import React from 'react'

export default function GoalSummary(){
    return(
        <> 
            <div className="tabContent_title font12 fw500 color7B8 pb15">MyFinsmart / <span className="color263">Goal Summary</span></div>

            <div className="font22 fw700 color0C4 pt30">Goal Summary</div>

            <div className="font14 fw500 color182 mt20">We have presented below a snapshot of all your major financial goals as shared by you. It is important to note that the Financial Plan below will largely revolve around helping you lay a roadmap for achieving your financial goals through various strategies as outlined therein.</div>

            <div className="font14 fw600 color626 mt50">DEFAULT GOAL</div>

            <div className="goalbox_outer mt20">
                <div className="goalbox_title">
                    <div>
                        <div className="font16 color263 fw600">Retirement Plan <span className="font13 fw500 color626">- Naveen S</span></div>
                    </div>
                    <div>
                        <div className="achivetab fw500 font12 color353">Projected Achievement <span className="color007 ml5">100%</span></div>
                    </div>
                </div>
                <div className="goalbox_content mt20">
                    <div className="goalbox_inner">
                        <div class="font13 fw500 color626">Current Yearly Expense</div>
                        <div class="font24 fw500 color263">15.45L</div>
                    </div>
                    <div className="goalbox_inner pr30">
                        <div className="goalstatus_outer">
                            <div className="fw500 font12 color182">7.5% Assumed Inflation</div>
                            <div className="goalstatus_bar mt10">
                                <div className="fw500 font12 color626 mr10">2022</div>
                                <div className="goalbar"><span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span></div>
                                <div className="fw500 font12 color626 ml10">2040</div>                     
                            </div>
                        </div>
                    </div>
                    <div className="goalbox_inner">
                        <div class="font13 fw500 color626">Total amount required</div>
                        <div class="font24 fw500 color22a">30.75L</div>
                    </div>
                </div>
            </div>


            <div className="font14 fw600 color626 mt50 mt40">CRUCIAL GOAL</div>

            <div className="goalbox_outer mt20">
                <div className="goalbox_title">
                    <div>
                        <div className="font16 color263 fw600">Retirement Plan <span className="font13 fw500 color626">- Naveen S</span></div>
                    </div>
                    <div>
                        <div className="achivetab fw500 font12 color353">Projected Achievement <span className="color007 ml5">100%</span></div>
                    </div>
                </div>
                <div className="goalbox_content mt20">
                    <div className="goalbox_inner">
                        <div class="font13 fw500 color626">Current Yearly Expense</div>
                        <div class="font24 fw500 color263">15.45L</div>
                    </div>
                    <div className="goalbox_inner pr30">
                        <div className="goalstatus_outer">
                            <div className="fw500 font12 color182">7.5% Assumed Inflation</div>
                            <div className="goalstatus_bar mt10">
                                <div className="fw500 font12 color626 mr10">2022</div>
                                <div className="goalbar"><span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span></div>
                                <div className="fw500 font12 color626 ml10">2040</div>                     
                            </div>
                        </div>
                    </div>
                    <div className="goalbox_inner">
                        <div class="font13 fw500 color626">Total amount required</div>
                        <div class="font24 fw500 color22a">30.75L</div>
                    </div>
                </div>
            </div>

            <div className="goalbox_outer mt20">
                <div className="goalbox_title">
                    <div>
                        <div className="font16 color263 fw600">Retirement Plan <span className="font13 fw500 color626">- Naveen S</span></div>
                    </div>
                    <div>
                        <div className="achivetab fw500 font12 color353">Projected Achievement <span className="color007 ml5">100%</span></div>
                    </div>
                </div>
                <div className="goalbox_content mt20">
                    <div className="goalbox_inner">
                        <div class="font13 fw500 color626">Current Yearly Expense</div>
                        <div class="font24 fw500 color263">15.45L</div>
                    </div>
                    <div className="goalbox_inner pr30">
                        <div className="goalstatus_outer">
                            <div className="fw500 font12 color182">7.5% Assumed Inflation</div>
                            <div className="goalstatus_bar mt10">
                                <div className="fw500 font12 color626 mr10">2022</div>
                                <div className="goalbar"><span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span></div>
                                <div className="fw500 font12 color626 ml10">2040</div>                     
                            </div>
                        </div>
                    </div>
                    <div className="goalbox_inner">
                        <div class="font13 fw500 color626">Total amount required</div>
                        <div class="font24 fw500 color22a">30.75L</div>
                    </div>
                </div>
            </div>


            <div className="font14 fw600 color626 mt50 mt40">LUXURY GOAL</div>

            <div className="goalbox_outer mt20">
                <div className="goalbox_title">
                    <div>
                        <div className="font16 color263 fw600">Retirement Plan <span className="font13 fw500 color626">- Naveen S</span></div>
                    </div>
                    <div>
                        <div className="achivetab fw500 font12 color353">Projected Achievement <span className="color007 ml5">100%</span></div>
                    </div>
                </div>
                <div className="goalbox_content mt20">
                    <div className="goalbox_inner">
                        <div class="font13 fw500 color626">Current Yearly Expense</div>
                        <div class="font24 fw500 color263">15.45L</div>
                    </div>
                    <div className="goalbox_inner pr30">
                        <div className="goalstatus_outer">
                            <div className="fw500 font12 color182">7.5% Assumed Inflation</div>
                            <div className="goalstatus_bar mt10">
                                <div className="fw500 font12 color626 mr10">2022</div>
                                <div className="goalbar"><span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span></div>
                                <div className="fw500 font12 color626 ml10">2040</div>                     
                            </div>
                        </div>
                    </div>
                    <div className="goalbox_inner">
                        <div class="font13 fw500 color626">Total amount required</div>
                        <div class="font24 fw500 color22a">30.75L</div>
                    </div>
                </div>
            </div>

            <div className="goalbox_outer mt20">
                <div className="goalbox_title">
                    <div>
                        <div className="font16 color263 fw600">Retirement Plan <span className="font13 fw500 color626">- Naveen S</span></div>
                    </div>
                    <div>
                        <div className="achivetab fw500 font12 color353">Projected Achievement <span className="color007 ml5">100%</span></div>
                    </div>
                </div>
                <div className="goalbox_content mt20">
                    <div className="goalbox_inner">
                        <div class="font13 fw500 color626">Current Yearly Expense</div>
                        <div class="font24 fw500 color263">15.45L</div>
                    </div>
                    <div className="goalbox_inner pr30">
                        <div className="goalstatus_outer">
                            <div className="fw500 font12 color182">7.5% Assumed Inflation</div>
                            <div className="goalstatus_bar mt10">
                                <div className="fw500 font12 color626 mr10">2022</div>
                                <div className="goalbar"><span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span></div>
                                <div className="fw500 font12 color626 ml10">2040</div>                     
                            </div>
                        </div>
                    </div>
                    <div className="goalbox_inner">
                        <div class="font13 fw500 color626">Total amount required</div>
                        <div class="font24 fw500 color22a">30.75L</div>
                    </div>
                </div>
            </div>

            <div className="mt50">ASSUMPTIONS</div>

            <div className="assumptionbox_outer mt25">
                <ul className="assumptionbox_list font13 fw500 color182">
                    <li>
                        <div>Asset</div>
                        <div>Growth</div>
                    </li>
                    <li>
                        <div className="font14 fw600">Equity Investments will grow at</div>
                        <div className="font14 fw600">10.00%</div>
                    </li>
                    <li>
                        <div className="font14 fw600">Debt Investments will grow at</div>
                        <div className="font14 fw600">8.00%</div>
                    </li>
                    <li>
                        <div className="font14 fw600">EPF will grow at</div>
                        <div className="font14 fw600">7.00%</div>
                    </li>
                    <li>
                        <div className="font14 fw600">Gold Investments will grow at</div>
                        <div className="font14 fw600">6.80%</div>
                    </li>
                    <li>
                        <div className="font14 fw600">Property value will grow at</div>
                        <div className="font14 fw600">6.80%</div>
                    </li>
                    <li>
                        <div className="font14 fw600">Rental Income from property will grow at</div>
                        <div className="font14 fw600">7.00%</div>
                    </li>
                    <li>
                        <div className="font14 fw600">Your Income will grow at</div>
                        <div className="font14 fw600">12.00%</div>
                    </li>
                </ul>
            </div>

            <div className="goalsum_chart_outer mt50">
                <div className="font14 fw600 color626">GOAL SUMMARY</div>
                <div className="goalsum_chart_box mt20">
                    <div className="font16 fw600 color636">Age of Retirement : 57</div>
                        <div className="goalsum_chart_devider mt20" />
                        <div className="goalbox_content mt20">
                            <div className="goalbox_inner">
                                <div class="font13 fw500 color626">Total Goal Amount</div>
                                <div class="font24 fw500 color263">12.52Cr</div>
                            </div>
                            <div className="goalbox_inner alignCenter pr30">
                                <div class="font13 fw500 color626">Yearly required</div>
                                <div class="font24 fw500 color263">23.75L</div>
                            </div>
                            <div className="goalbox_inner">
                                <div class="font13 fw500 color626">Currently linked</div>
                                <div class="font24 fw500 color22a">95L</div>
                            </div>
                        </div>
                        <div className="goalsum_chart_devider mt20" />
                        <div className="goalbox_content mt20">
                            <div className="goalbox_inner">
                                <div class="font13 fw500 color626">This year’s need</div>
                                <div class="font24 fw500 color263">19.35L</div>
                            </div>
                            <div className="goalbox_inner alignCenter pr30">
                                <div class="font13 fw500 color626">Increased yearly by</div>
                                <div class="font24 fw500 color263">15%</div>
                            </div>
                            <div className="goalbox_inner">
                                <div class="font13 fw500 color626">Pre-return</div>
                                <div class="font24 fw500 color22a">9.64%</div>
                            </div>
                        </div>
                </div>
            </div>

            <div className="font18 fw600 color181 mt70">Cashflow for existing goal plans  </div>
            <div className="goalPlan_tbl mt25">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                            <th scope="col">Year</th>
                            <th scope="col"><span className="dotstatus">Outflow</span></th>
                            <th scope="col"><span className="dotstatus darkyellowdot">Fresh Inv.</span></th>
                            <th scope="col"><span className="dotstatus greendot">Existing Inv.</span></th>
                            <th scope="col"><span className="dotstatus bluedot">Future Value</span></th>
                            <th scope="col">Net Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">2022</th>
                                <td>2,67,500</td>
                                <td>40,13,476</td>
                                <td>1,83,00,000</td>
                                <td>2,45,44,824</td>
                                <td>2,42,77,324</td>
                            </tr>
                            <tr>
                                <th scope="row">2023</th>
                                <td>2,86,225</td>
                                <td>40,13,476</td>
                                <td>1,93,00,000</td>
                                <td>3,11,19,879</td>
                                <td>3,08,33,654</td>
                            </tr>
                            <tr>
                                <th scope="row">2024</th>
                                <td>3,06,261</td>
                                <td>40,13,476</td>
                                <td>2,07,00,000</td>
                                <td>3,83,31,843</td>
                                <td>3,80,25,582</td>
                            </tr>
                            <tr>
                                <th scope="row">2022</th>
                                <td>2,67,500</td>
                                <td>40,13,476</td>
                                <td>1,83,00,000</td>
                                <td>2,45,44,824</td>
                                <td>2,42,77,324</td>
                            </tr>
                            <tr>
                                <th scope="row">2023</th>
                                <td>2,86,225</td>
                                <td>40,13,476</td>
                                <td>1,93,00,000</td>
                                <td>3,11,19,879</td>
                                <td>3,08,33,654</td>
                            </tr>
                            <tr>
                                <th scope="row">2024</th>
                                <td>3,06,261</td>
                                <td>40,13,476</td>
                                <td>2,07,00,000</td>
                                <td>3,83,31,843</td>
                                <td>3,80,25,582</td>
                            </tr>
                            <tr>
                                <th scope="row">2022</th>
                                <td>2,67,500</td>
                                <td>40,13,476</td>
                                <td>1,83,00,000</td>
                                <td>2,45,44,824</td>
                                <td>2,42,77,324</td>
                            </tr>
                            <tr>
                                <th scope="row">2023</th>
                                <td>2,86,225</td>
                                <td>40,13,476</td>
                                <td>1,93,00,000</td>
                                <td>3,11,19,879</td>
                                <td>3,08,33,654</td>
                            </tr>
                            <tr>
                                <th scope="row">2024</th>
                                <td>3,06,261</td>
                                <td>40,13,476</td>
                                <td>2,07,00,000</td>
                                <td>3,83,31,843</td>
                                <td>3,80,25,582</td>
                            </tr>
                            <tr>
                                <th scope="row">2022</th>
                                <td>2,67,500</td>
                                <td>40,13,476</td>
                                <td>1,83,00,000</td>
                                <td>2,45,44,824</td>
                                <td>2,42,77,324</td>
                            </tr>
                            <tr>
                                <th scope="row">2023</th>
                                <td>2,86,225</td>
                                <td>40,13,476</td>
                                <td>1,93,00,000</td>
                                <td>3,11,19,879</td>
                                <td>3,08,33,654</td>
                            </tr>
                            <tr>
                                <th scope="row">2024</th>
                                <td>3,06,261</td>
                                <td>40,13,476</td>
                                <td>2,07,00,000</td>
                                <td>3,83,31,843</td>
                                <td>3,80,25,582</td>
                            </tr>
                            <tr>
                                <th scope="row">2022</th>
                                <td>2,67,500</td>
                                <td>40,13,476</td>
                                <td>1,83,00,000</td>
                                <td>2,45,44,824</td>
                                <td>2,42,77,324</td>
                            </tr>
                            <tr>
                                <th scope="row">2023</th>
                                <td>2,86,225</td>
                                <td>40,13,476</td>
                                <td>1,93,00,000</td>
                                <td>3,11,19,879</td>
                                <td>3,08,33,654</td>
                            </tr>
                            <tr>
                                <th scope="row">2024</th>
                                <td>3,06,261</td>
                                <td>40,13,476</td>
                                <td>2,07,00,000</td>
                                <td>3,83,31,843</td>
                                <td>3,80,25,582</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </>
    )
}