import React from 'react'


export default function Dashboardgoals(){
    return(
        <>
            <div className="goalsbanner ">
                <div>
                    <div className="font13 fw500 color182">Naveen’s Retirement Corpus</div>
                    <div className="font11 fw500 color0d6">2044</div>
                </div>
                <div>
                    <div className="font18 fw500 color182">4.45Cr</div>
                </div>
            </div>  

            <div className="flex_center justify_center pl30 pr30 pt20 pb20 mob_pl15_pr15">
                <div className="font13 fw500 color182">4 Goals in the financial journey</div>
                <div className="addgoal_btn">
                    <button type="button" class="btn btn-light color182 font12 fw500"><span className="goal_blueclr mr5" /> Add Goal</button>
                </div>
            </div>

            <div className="goallist_outer">
                <div className="goallist_title color8c9 font11 fw600">CRUCIAL GOALS</div>
                <ul className="goallist">
                    <li>
                        <div className="goallist_left">
                            <div className="font11 fw500 color0d6">2031</div>
                            <div className="goallist_icon" />
                            <div className="font13 fw500 color182">Daughter’s education</div>
                            <div className="goaledit_icon ml4 d-none d-sm-block" />
                        </div>
                        <div className="rangebox ml12">
                            <div className="goal_range_slider pr10 d-none d-sm-block">
                                <input type="range" class="custom-range" min="0" max="4" id="customRange2" />
                            </div>
                            <div className="font13 fw500 color182">84.5L <span className="arrow-right d-block d-sm-none" /></div>
                        </div>
                    </li>
                    <li>
                        <div className="goallist_left">
                            <div className="font11 fw500 color0d6">2025</div>
                            <div className="goallist_icon" />
                            <div className="font13 fw500 color182">Buy Beach home</div>
                            <div className="goaledit_icon d-none d-sm-block ml4" />
                        </div>
                        <div className="rangebox ml12">
                            <div className="goal_range_slider pr10 d-none d-sm-block">
                                <input type="range" class="custom-range" min="0" max="4" id="customRange2" />
                            </div>
                            <div className="font13 fw500 color182">84.5L <span className="arrow-right d-block d-sm-none" /></div>
                        </div>
                    </li>
                </ul>
            </div>


            <div className="goallist_outer mt20">
                <div className="goallist_title color8c9 font11 fw600">LUXURY GOALS</div>
                <ul className="goallist">
                    <li>
                        <div className="goallist_left">
                            <div className="font11 fw500 color0d6">2031</div>
                            <div className="goallist_icon" />
                            <div className="font13 fw500 color182">Grand vacation</div>
                            <div className="goaledit_icon d-none d-sm-block ml4" />
                        </div>
                        <div className="rangebox ml12">
                            <div className="goal_range_slider pr10 d-none d-sm-block">
                                <input type="range" class="custom-range" min="0" max="4" id="customRange2" />
                            </div>
                            <div className="font13 fw500 color182">84.5L <span className="arrow-right d-block d-sm-none" /></div>
                        </div>
                    </li>
                    <li>
                        <div className="goallist_left">
                            <div className="font11 fw500 color0d6">2025</div>
                            <div className="goallist_icon" />
                            <div className="font13 fw500 color182">Mercendes Benz</div>
                            <div className="goaledit_icon d-none d-sm-block ml4" />
                        </div>
                        <div className="rangebox ml12">
                            <div className="goal_range_slider pr10 d-none d-sm-block">
                                <input type="range" class="custom-range" min="0" max="4" id="customRange2" />
                            </div>
                            <div className="font13 fw500 color182">84.5L <span className="arrow-right d-block d-sm-none" /></div>
                        </div>
                    </li>
                </ul>
            </div>
                
        </>
    )
}