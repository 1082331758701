import React from 'react'


export default function Dashboardcashflow(){
    return(
        <>  
            <div className="db_cashflow_outer pt20 pl30 pr30">
                <ul class="nav nav-pills mb-3 font12 fw500 color353" id="pills-tab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="pills-overview-tab" data-toggle="pill" href="#pills-overview" role="tab" aria-controls="pills-overview" aria-selected="true">Overview</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="pills-inflow-tab" data-toggle="pill" href="#pills-inflow" role="tab" aria-controls="pills-inflow" aria-selected="false">Inflow 18.5L</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="pills-outflow-tab" data-toggle="pill" href="#pills-outflow" role="tab" aria-controls="pills-outflow" aria-selected="false">Outflow 10.1L</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="pills-taxation-tab" data-toggle="pill" href="#pills-taxation" role="tab" aria-controls="pills-taxation" aria-selected="false">Taxation</a>
                    </li>
                </ul>
                <div class="tab-content pt10" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-overview" role="tabpanel" aria-labelledby="pills-overview-tab">
                    <div className="cashflow_list pb15">
                        <div className="cashflow_list_title font15 fw500 color182">
                            Total Inflow
                        </div>
                        <div className="font12 fw500 color626">
                            Your family’s total income including returns and inheritance.
                        </div>
                        <div className="font20 fw500 color182 mt12">18.5L</div>
                    </div>
                    <div className="cashflow_list pb15 mt20">
                        <div className="cashflow_list_title orangedotclr font15 fw500 color182">
                            Total Outflow
                        </div>
                        <div className="font12 fw500 color626">
                            Your family’s total income including returns and inheritance.
                        </div>
                        <div className="font20 fw500 color182 mt12">10.1L</div>
                    </div>
                </div>
                <div class="tab-pane fade" id="pills-inflow" role="tabpanel" aria-labelledby="pills-inflow-tab">
                    <div className="cashflow_inflow_wpr">
                        <div className="cashflow_inflow_title">
                            <div>
                                <div className="font13 fw500 color6d7">Total Inflow</div>
                                <div className="font24 fw500 color182">18.5L</div>
                            </div>
                            <div>
                                <button type="button" class="btn btn-light addinflow_btn font12 fw500 color182"><span className="cashflow_peechclr mr5" /> Add</button>
                            </div>
                        </div>
                        <div className="inflow_progressbar mt15">
                            <progress value="75" max="100"></progress>
                        </div>
                        <div className="earnincome_outer mt20">
                            <div className="earnincome_title font11 fw600 color6d7">
                                EARNED INCOME
                            </div>
                            <div className="incomeinfo mt15">
                                <div>
                                    <div className="font12 fw500 color6d7">Fixed income</div>
                                    <div className="font16 fw500 color162">8.5L</div>
                                </div>
                                <div>
                                    <div className="font12 fw500 color6d7">Variable inc.</div>
                                    <div className="font16 fw500 color162">1.2L</div>
                                </div>
                                <div>
                                    <div className="font12 fw500 color6d7">Spouse’s inc.</div>
                                    <div className="font16 fw500 color162">4.1L</div>
                                </div>
                            </div>
                        </div>
                        <div className="earnincome_outer mt20">
                            <div className="earnincome_title font11 fw600 color6d7">
                                OTHER INCOME
                            </div>
                            <div className="incomeinfo mt15">
                                <div>
                                    <div className="font12 fw500 color6d7">Rental</div>
                                    <div className="font16 fw500 color162">8.5L</div>
                                </div>
                                <div>
                                    <div className="font12 fw500 color6d7">Investment</div>
                                    <div className="font16 fw500 color162">00</div>
                                </div>
                                <div>
                                    <div className="font12 fw500 color6d7">Inheritance</div>
                                    <div className="font16 fw500 color162">4.1L</div>
                                </div>
                                <div>
                                    <div className="font12 fw500 color6d7">Others</div>
                                    <div className="font16 fw500 color162">0.5L</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="tab-pane fade" id="pills-outflow" role="tabpanel" aria-labelledby="pills-outflow-tab">
                    <div className="cashflow_inflow_wpr cashflow_outflow_wpr">
                        <div className="cashflow_inflow_title">
                            <div>
                                <div className="font13 fw500 color6d7">Total Inflow</div>
                                <div className="font24 fw500 color182">18.5L</div>
                            </div>
                            <div>
                                <button type="button" class="btn btn-light addinflow_btn font12 fw500 color182"><span className="cashflow_orngclr mr5" /> Add</button>
                            </div>
                        </div>
                        <div className="inflow_progressbar mt15">
                            <progress value="75" max="100"></progress>
                        </div>
                        <div className="earnincome_outer mt20">
                            <div className="earnincome_title font11 fw600 color6d7 orangclr_dot">
                                EXPENSE <span className="color182 ml8">6.2L</span>
                            </div>
                            <div className="incomeinfo mt15">
                                <div>
                                    <div className="font12 fw500 color6d7">Household</div>
                                    <div className="font16 fw500 color162">8.5L</div>
                                </div>
                                <div>
                                    <div className="font12 fw500 color6d7">Education</div>
                                    <div className="font16 fw500 color162">1.2L</div>
                                </div>
                                <div>
                                    <div className="font12 fw500 color6d7">Medicine</div>
                                    <div className="font16 fw500 color162">4.1L</div>
                                </div>
                                <div>
                                    <div className="font12 fw500 color6d7">Others</div>
                                    <div className="font16 fw500 color162">4.1L</div>
                                </div>
                            </div>
                        </div>
                        <div className="earnincome_outer mt20">
                            <div className="earnincome_title justify_center font11 fw600 color6d7 orangclr_dot">
                                <div>
                                    EMI <span className="color182 ml8">2.1L</span>
                                </div>
                                <div class="switch_btn font12 fw500">
                                    Suggestion
                                    <label class="switch ml8">
                                        <input type="checkbox" checked />
                                        <span class="slider"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="incomeinfo mt15">
                                <div>
                                    <div className="font12 fw500 color6d7">Home loan</div>
                                    <div className="font16 fw500 color162">8.5L</div>
                                </div>
                                <div>
                                    <div className="font12 fw500 color6d7">Car Loan</div>
                                    <div className="font16 fw500 color162">00 <span className="loandown font10 fw500">7.5L</span></div>
                                </div>
                            </div>
                        </div>
                        <div className="earnincome_outer mt20">
                            <div className="earnincome_title justify_center font11 fw600 color6d7 darkorangclr_dot">
                                <div>
                                    COMMITMENTS <span className="color182 ml8">0.8L</span>
                                </div>

                                <div class="switch_btn font12 fw500">
                                    Suggestion
                                    <label class="switch ml8">
                                        <input type="checkbox" />
                                        <span class="slider"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="incomeinfo mt15">
                                <div>
                                    <div className="font12 fw500 color6d7">Health Insurance</div>
                                    <div className="font16 fw500 color162">8.5L</div>
                                </div>
                                <div>
                                    <div className="font12 fw500 color6d7">Term Insurance</div>
                                    <div className="font16 fw500 color162">00</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="tab-pane fade" id="pills-taxation" role="tabpanel" aria-labelledby="pills-taxation-tab">
                    <div className="cashflow_inflow_wpr cashflow_outflow_wpr">
                        <div className="cashflow_inflow_title">
                            <div>
                                <div className="font13 fw500 color6d7">Total tax paid for FY2021-2022</div>
                                <div className="font24 fw500 color182">2.8L</div>
                            </div>
                            <div>
                                <button type="button" class="btn btn-light addinflow_btn font12 fw500 color182"><span className="edit_icon mr5" /> Add</button>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </>
    )
}