import React from 'react'

export default function EmergencyFund(){
    return(
        <> 
            <div className="tabContent_title font12 fw500 color7B8 pb15">MyFinsmart / <span className="color263">Emergency Fund</span></div>

            <div className="font22 fw700 color0C4 pt30">Emergency Funds</div>

            <div className="font14 fw500 color182 mt20">It is important to maintain an emergency fund to meet the expenses arising out of any unforeseen events like unexpected medical expenses . Emergency funds should be invested in short-term investments such as liquid funds, Bank Flexi- Deposits or a combination of such ultra liquid instruments.</div>

            <div className="font14 fw500 color182 mt50">Note : Current mapped amount for Emergency fund is 0</div>

            <div className="suggestBox mt20">
                <div className="suggestBox_left">
                    <div className="font16 fw600 color0f7">Suggested Emergency fund</div>
                    <div className="font14 fw400 color505">In ultra liquid instruments.</div>
                </div>
                <div className="suggestBox_right">
                    <div className="font24 fw500 color182">8.4L - 12.6L</div>
                </div>
            </div>

        </>
    )
}