import React from 'react'
import Dashboardleft from '../components/dashboard_left'
import Dashboardright from '../components/dashboard_right'
// import Editinflowpopup from '../components/editinflowpopup'
// import EditearnIncomepopup from '../components/editearnincomepopup'
// import Editexpensespopup from '../components/editexpensespopup'
// import Editcommitmentspopup from '../components/editcommitmentspopup'
// import Editeducationgoalpopup from '../components/editeducationgoalpopup'
// import Editemipopup from '../components/editemipopup'
// import Editcommitmentspopup2 from '../components/editcommitmentspopup2'
// import Edithomegoal from '../components/edithomegoal'
// import Behindrational from '../components/behindrational'

export default function Dashboard(){
    return(
        <>
            <div className="innercontainer mb40">
                <div className="mainwrapper">
                <div className="mt30 font21 fw600 color384 mob_pl20_pr20">Naveen’s Financial plan</div>
                <div className="font16 color626 mob_pl20_pr20">Calculation of required amount to achieve desired financial goals.</div>

                <div className="infoBox_wpr mt25">
                    <div className="infoBox_outer act">
                        <div className="infoBox_title">Total need to achieve all the goals</div>
                        <div className="mt15 infoBox_number goldtext">
                            12.4Cr
                        </div>
                        <div className="infoBox_footertext mt20">4.42Cr Retirement corpus</div>
                    </div>
                    <div className="infoBox_outer">
                        <div className="infoBox_title">Your total investments for goals so far</div>
                        <div className="mt15 infoBox_number greentext">
                            1.83Cr
                        <progress value="25" max="100"></progress>
                        </div>
                        <div className="infoBox_footertext mt20">10.54 Cr remaining</div>
                    </div>
                    <div className="infoBox_outer">
                        <div className="infoBox_title">
                            Next year’s need
                            <div>Today Sep, 2023</div>
                        </div>
                        <div className="mt15 infoBox_number bluetext">
                            12.4Cr
                        </div>
                        <div className="infoBox_footertext mt20">4.42Cr Retirement corpus</div>
                    </div>
                    <div className="infoBox_outer">
                        <div className="infoBox_title">Total need to achieve all the goals</div>
                        <div className="mt15 infoBox_number goldtext">
                            12.4Cr
                            <progress value="50" max="100"></progress>
                        </div>
                        <div className="infoBox_footertext mt20">4.42Cr Retirement corpus</div>
                    </div>
                    <div className="infoBox_outer">
                        <div className="infoBox_title">Total need to achieve all the goals</div>
                        <div className="mt15 infoBox_number redtext">
                            12.4Cr
                        </div>
                        <div className="infoBox_footertext mt20">4.42Cr Retirement corpus</div>
                    </div>
                </div>


                <div className="dashboard_outer mt30">
                    <Dashboardleft />
                    <Dashboardright />
                </div>

                {/* <Editinflowpopup /> */}
                {/* <EditearnIncomepopup /> */}
                {/* <Editexpensespopup /> */}
                {/* <Editcommitmentspopup /> */}
                {/* <Editeducationgoalpopup /> */}
                {/* <Editemipopup /> */}
                {/* <Editcommitmentspopup2 /> */}
                {/* <Edithomegoal /> */}
                {/* <Behindrational /> */}
                </div>
            </div>
        </>
    )
}