import React from 'react'

export default function Assetswhatyouown(){
    return(
        <> 
            <div className="tabContent_title font12 fw500 color7B8 pb15">MyFinsmart / <span className="color263">Networth Analysis</span></div>

            <div className="font22 fw700 color0C4 pt30">Networth Analysis</div>

            <div className="totalSaving_box mt15">
                <div>
                    <div class="font12 fw600 color6d7">TOTAL ASSETS</div>
                    <div class="font24 fw500 color263 mt12">18.5L</div>
                </div>
                <div className="circleBtn_outer">
                    <div className="circleBtn">-</div>
                </div>
                <div>
                    <div class="font12 fw600 color6d7">TOTAL LIABILITIES </div>
                    <div class="font24 fw500 color263 mt12">18.5L</div>
                </div>
                <div className="circleBtn_outer">
                    <div className="circleBtn">=</div>
                </div>
                <div>
                    <div class="font12 fw600 color6d7">TOTAL NETWORTH</div>
                    <div class="font24 fw500 color263 mt12">18.5L</div>
                </div>
            </div>
            <div className="font12 fw500 color78B mt15">For details of your existing liabilities , please see the Loans Management section.</div>

            <div className="lineDevider mt40 mb40" />
            
            <div className="font22 fw700 color0C4">Risk Profile</div>
            <div className="riskbar_outer mt50">
                <div className="riskbar_sctn">
                    <div className="riskbar_status"></div>
                    <div className="font13 fw500 color485 mt10">Very Caution</div>
                </div>
                <div className="riskbar_sctn">
                    <div className="riskbar_status"></div>
                    <div className="font13 fw500 color485 mt10">Caution</div>
                </div>
                <div className="riskbar_sctn">
                    <div className="riskbar_status"></div>
                    <div className="font13 fw500 color485 mt10">Moderate</div>
                </div>
                <div className="riskbar_sctn">
                    <div className="riskbar_status"></div>
                    <div className="font13 fw500 color485 mt10">Aggressive</div>
                </div>
                <div className="riskbar_sctn">
                    <div className="riskbar_status"></div>
                    <div className="font13 fw500 color485 mt10">Very Aggressive</div>
                </div>
            </div>

        

        </>
    )
}