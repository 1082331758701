import React from 'react'

export default function LifeInsuranceAnalysis(){
    return(
        <> 
            <div className="tabContent_title font12 fw500 color7B8 pb15">MyFinsmart / <span className="color263">Life Insurance Analysis</span></div>

            <div className="font22 fw700 color0C4 pt30">Life Insurance Analysis</div>

            <div className="font14 fw500 color182 mt20">Your life insurance is a financial cover that you have in case on any eventuality related to your life. It your family with a financial security for their living expenses, goals, any liabilities etc.</div>

            <div className="font18 fw500 color mt25">Life Insurance Plan For : <b>Naveen S</b></div>

            <div className="objectivenote mt25">
                <div className="font14 fw600 color1e1">Objective :</div>
                <div className="font14 fw500 color384">To cover an Yearly Income of Rs 24,00,000 OR Yearly Expenses of Rs. 8,00,000 for next 42 years</div>
            </div>

            <div className="analysisnote mt8">
                <div className="font14 fw600 color1e1">Objective :</div>
                <ul className="analysislist font14 fw500">
                    <li>Assumed rate of Growth in your income over the years is 0 % and for your expenses is 6 %</li>
                    <li>The additional Life Insurance Cover required is in the range of Rs 0 to Rs. 0</li>
                </ul>
            </div>

            <div className="lifecoveredbox_outer mt25">
                <div className="lifecoveredbox">
                    <div className="font14 fw500 color263">Your Existing Life Cover Is</div>
                    <div className="flex_center justify_center mt20">
                        <div>
                            <div className="font13 fw500 color626">Minimum</div>
                            <div className="fw500 font16 color182">20.5L</div>
                        </div>
                        <div>
                            <div className="font13 fw500 color626">Minimum</div>
                            <div className="fw500 font16 color182">20.5L</div>
                        </div>
                    </div>
                </div>
                <div className="lifecoveredbox">
                <div className="font14 fw500 color263">Life Covered Required</div>
                    <div className="flex_center justify_center mt20">
                        <div>
                            <div className="font13 fw500 color626">Minimum</div>
                            <div className="fw500 font16 color007">20.5L</div>
                        </div>
                        <div>
                            <div className="font13 fw500 color626">Minimum</div>
                            <div className="fw500 font16 color007">20.5L</div>
                        </div>
                    </div>
                </div>
                <div className="lifecoveredbox">
                <div className="font14 fw500 color263">Additional Cover Required</div>
                    <div className="flex_center justify_center mt20">
                        <div>
                            <div className="font13 fw500 color626">Minimum</div>
                            <div className="fw500 font16 coloreb4">20.5L</div>
                        </div>
                        <div>
                            <div className="font13 fw500 color626">Minimum</div>
                            <div className="fw500 font16 coloreb4">20.5L</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="loaninfo font13 mt25">
                Assumptions: Rate Of Return on the Fund Received = <b>9 %</b>
            </div>
            <div className="lineDevider mt40 mb40" />


            <div className="font18 fw500 color mt25">Life Insurance Plan For : <b>Spouse</b></div>

            <div className="objectivenote mt25">
                <div className="font14 fw600 color1e1">Objective :</div>
                <div className="font14 fw500 color384">To cover an Yearly Income of Rs 24,00,000 OR Yearly Expenses of Rs. 8,00,000 for next 42 years</div>
            </div>

            <div className="analysisnote mt8">
                <div className="font14 fw600 color1e1">Objective :</div>
                <ul className="analysislist font14 fw500">
                    <li>Assumed rate of Growth in your income over the years is 0 % and for your expenses is 6 %</li>
                    <li>The additional Life Insurance Cover required is in the range of Rs 0 to Rs. 0</li>
                </ul>
            </div>

            <div className="lifecoveredbox_outer mt25">
                <div className="lifecoveredbox">
                    <div className="font14 fw500 color263">Your Existing Life Cover Is</div>
                    <div className="flex_center justify_center mt20">
                        <div>
                            <div className="font13 fw500 color626">Minimum</div>
                            <div className="fw500 font16 color182">20.5L</div>
                        </div>
                        <div>
                            <div className="font13 fw500 color626">Minimum</div>
                            <div className="fw500 font16 color182">20.5L</div>
                        </div>
                    </div>
                </div>
                <div className="lifecoveredbox">
                <div className="font14 fw500 color263">Life Covered Required</div>
                    <div className="flex_center justify_center mt20">
                        <div>
                            <div className="font13 fw500 color626">Minimum</div>
                            <div className="fw500 font16 color007">20.5L</div>
                        </div>
                        <div>
                            <div className="font13 fw500 color626">Minimum</div>
                            <div className="fw500 font16 color007">20.5L</div>
                        </div>
                    </div>
                </div>
                <div className="lifecoveredbox">
                <div className="font14 fw500 color263">Additional Cover Required</div>
                    <div className="flex_center justify_center mt20">
                        <div>
                            <div className="font13 fw500 color626">Minimum</div>
                            <div className="fw500 font16 coloreb4">20.5L</div>
                        </div>
                        <div>
                            <div className="font13 fw500 color626">Minimum</div>
                            <div className="fw500 font16 coloreb4">20.5L</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="loaninfo font13 mt25">
                Assumptions: Rate Of Return on the Fund Received = <b>9 %</b>
            </div>
            <div className="lineDevider mt40 mb40" />


            <div className="font18 fw600 color182">Details of your Existing Life Insurance</div>

            <div className="loanBox_outer mt20 p25">
                <div className="loandtl">
                    <div className="font16 fw600 color263">Naveen Singh</div>
                    <div className="font11 fw500 color7b8">Policy Holder</div>
                </div>
                <div className="loandtl">
                    <div className="font12 fw500 color6d7">Sum Insured</div>
                    <div className="font16 fw500 color162">80.5L</div>
                </div>
                <div className="loandtl">
                    <div className="font12 fw500 color6d7">Premium Amount</div>
                    <div className="font16 fw500 color162">20K</div>
                </div>
                <div className="loandtl">
                    <div className="font12 fw500 color6d7">Premium Frequency</div>
                    <div className="font16 fw500 color162">Yearly</div>
                </div>
            </div>

            <div className="loanBox_outer mt20 p25">
                <div className="loandtl">
                    <div className="font16 fw600 color263">Spouse Name</div>
                    <div className="font11 fw500 color7b8">Policy Holder</div>
                </div>
                <div className="loandtl">
                    <div className="font12 fw500 color6d7">Sum Insured</div>
                    <div className="font16 fw500 color162">80.5L</div>
                </div>
                <div className="loandtl">
                    <div className="font12 fw500 color6d7">Premium Amount</div>
                    <div className="font16 fw500 color162">20K</div>
                </div>
                <div className="loandtl">
                    <div className="font12 fw500 color6d7">Premium Frequency</div>
                    <div className="font16 fw500 color162">Yearly</div>
                </div>
            </div>
            


        </>
    )
}