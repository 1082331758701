import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import Dashboard from './pages/dashboard'
import Plan from './pages/plan'

export default function RouterPage() {

    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />}></Route>
                    <Route path="/dashboard" element={<Dashboard />}></Route>
                    <Route path="/plan" element={<Plan />}></Route>
                </Routes>
            </BrowserRouter>
        </div>
    )
}
