import React from 'react';
import './css/common.css';
import Header from './components/header'

import RouterPage from './RouterPage';
function App() {
  return (
    <React.Fragment>
      <div className="App">
        <Header />
        <RouterPage />
      </div>
    </React.Fragment>
  );
}

export default App;
