import React from 'react'
import WelcomeNote from '../components/welcomenote'
import Disclaimer from '../components/disclaimer'
import PersonalInformation from '../components/personalinformation'
import CashflowAnalysis from '../components/cashflowanalysis'
import Assetswhatyouown from '../components/assetswhatyouown'
import NetworthAnalysis from '../components/networthanalysis'
import EmergencyFund from '../components/emergencyfund'
import LoanManagement from '../components/loanmanagement'
import LifeInsuranceAnalysis from '../components/lifeinsuranceanalysis'
import HealthAnalysis from '../components/healthanalysis'
import GoalSummary from '../components/goalsummary'
// import Deliveryacknowledgement from '../components/deliveryacknowledgement'


export default function Plan(){
    return(
        <>
            <div className="innercontainer mb40">
                <div className="mainwrapper">
                <div class="planSection_wpr row">
                    <div class="col-3 leftNav pt55 font14 fw500 color7B8">
                        <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <a class="nav-link active" id="Welcomenote-tab" data-toggle="pill" href="#Welcomenote" role="tab" aria-controls="Welcomenote" aria-selected="true">Welcome note</a>
                        <a class="nav-link" id="Disclaimer-tab" data-toggle="pill" href="#Disclaimer" role="tab" aria-controls="Disclaimer" aria-selected="false">Disclaimer</a>
                        <a class="nav-link" id="PersonalInformation-tab" data-toggle="pill" href="#PersonalInformation" role="tab" aria-controls="PersonalInformation" aria-selected="false">Personal Information</a>
                        <a class="nav-link" id="CashflowAnalysis-tab" data-toggle="pill" href="#CashflowAnalysis" role="tab" aria-controls="CashflowAnalysis" aria-selected="false">Cashflow Analysis</a>
                        <a class="nav-link" id="Whatyouown-tab" data-toggle="pill" href="#Whatyouown" role="tab" aria-controls="Whatyouown" aria-selected="false">Assets - What you own</a>
                        <a class="nav-link" id="NetworthAnalysis-tab" data-toggle="pill" href="#NetworthAnalysis" role="tab" aria-controls="NetworthAnalysis" aria-selected="false">Networth Analysis</a>
                        <a class="nav-link" id="EmergencyFund-tab" data-toggle="pill" href="#EmergencyFund" role="tab" aria-controls="EmergencyFund" aria-selected="false">Emergency Fund</a>
                        <a class="nav-link" id="LoanManagement-tab" data-toggle="pill" href="#LoanManagement" role="tab" aria-controls="LoanManagement" aria-selected="false">Loan Management</a>
                        <a class="nav-link" id="LifeInsuranceAnalysis-tab" data-toggle="pill" href="#LifeInsuranceAnalysis" role="tab" aria-controls="LifeInsuranceAnalysis" aria-selected="false">Life Insurance Analysis</a>
                        <a class="nav-link" id="HealthAnalysis-tab" data-toggle="pill" href="#HealthAnalysis" role="tab" aria-controls="HealthAnalysis" aria-selected="false">Health Analysis</a>
                        <a class="nav-link" id="GoalSummary-tab" data-toggle="pill" href="#GoalSummary" role="tab" aria-controls="GoalSummary" aria-selected="false">Goal Summary</a>
                        </div>

                        <div className="downloadBtn pt30">
                            <button type="button" class="btn btn-primary btn-lg btn-block font13 fw500">Download as PDF</button>
                        </div>
                    </div>
                    <div class="col-9 righttabContent pl90 pt25">
                        <div class="tab-content" id="v-pills-tabContent">
                        <div class="tab-pane fade show active" id="Welcomenote" role="tabpanel" aria-labelledby="Welcomenote-tab">
                            <WelcomeNote/>

                        </div>
                        <div class="tab-pane fade" id="Disclaimer" role="tabpanel" aria-labelledby="Disclaimer-tab">
                            <Disclaimer/>
                        </div>
                        <div class="tab-pane fade" id="PersonalInformation" role="tabpanel" aria-labelledby="PersonalInformation-tab">
                            <PersonalInformation/>
                        </div>
                        <div class="tab-pane fade" id="CashflowAnalysis" role="tabpanel" aria-labelledby="CashflowAnalysis-tab">
                            <CashflowAnalysis/>
                        </div>
                        <div class="tab-pane fade" id="Whatyouown" role="tabpanel" aria-labelledby="Whatyouown-tab">
                            <Assetswhatyouown/>
                        </div>
                        <div class="tab-pane fade" id="NetworthAnalysis" role="tabpanel" aria-labelledby="NetworthAnalysis-tab">
                            <NetworthAnalysis/>
                        </div>
                        <div class="tab-pane fade" id="EmergencyFund" role="tabpanel" aria-labelledby="EmergencyFund-tab">
                            <EmergencyFund/>
                        </div>
                        <div class="tab-pane fade" id="LoanManagement" role="tabpanel" aria-labelledby="LoanManagement-tab">
                            <LoanManagement/>
                        </div>
                        <div class="tab-pane fade" id="LifeInsuranceAnalysis" role="tabpanel" aria-labelledby="LifeInsuranceAnalysis-tab">
                            <LifeInsuranceAnalysis/>
                        </div>
                        <div class="tab-pane fade" id="HealthAnalysis" role="tabpanel" aria-labelledby="HealthAnalysis-tab">
                            <HealthAnalysis/>
                        </div>
                        <div class="tab-pane fade" id="GoalSummary" role="tabpanel" aria-labelledby="GoalSummary-tab">
                            <GoalSummary/>
                            {/* <Deliveryacknowledgement/> */}
                        </div>
                        </div>
                    </div>
                </div>

                </div>
            </div>
        </>
    )
}