import React from 'react'

export default function Disclaimer(){
    return(
        <> 
            <div className="tabContent_title font12 fw500 color7B8 pb15">MyFinsmart / <span className="color263">Disclaimer</span></div>

            <div className="font22 fw700 color0C4 pt30">Disclaimer</div>

            <div className="font14 fw500 color182 pt20">This financial plan is for the sole use of the person to whom it is addressed and for no other purpose. No responsibility is accepted to any third party who may use or rely on the content of the financial plan.</div>

            <div className="font14 fw500 color182 pt20">This financial plan is designed from the personal information and documents furnished to us by you. The plan is an attempt to help you lay a roadmap to achieve your financial goals in a systematic and simplistic manner.</div>

            <div className="font14 fw500 color182 pt20">The financial plan shows ballpark estimates of your future financial situation, and is intended only as a basis for discussion with us or, for that matter, any financial advisor. The estimates shown in this report are based on many assumptions that may or may not occur. Your investment returns will fluctuate over time. The only assurance is that over time, every investment program is likely to produce some losses on the road to achieving long-term gains.</div>

            <div className="font14 fw500 color182 pt20">No warranty as to correctness is given and no liability is accepted for any error, or omission, or any loss, which may arise from relying on this data. This service agreement is not enforceable by law and is a document of trust and faith.</div>

            <div className="font14 fw500 color182 pt20">While external factors like inflation, rate of return on investments etc. changes continuously, so does your financial situation. You are advised to periodically review the financial plan to make sure you are on track to achieve your financial goals. This plan is an on-going exercise as part of your long term financial planning process.</div>


            <div className="font14 fw500 color182 pt20">This plan is only as accurate as the information on which it was based. If the data originally supplied to us is incorrect, the plan will reflect these inaccuracies, and these errors will project into the future at a magnified rate.</div>


            <div className="font14 fw500 color182 pt20">It is essential that the tax, accounting or legal planning steps be considered only with the advice of your lawyer, chartered accountant, and your other financial advisors, which we will be happy to coordinate with.</div>


            <div className="font14 fw500 color182 pt20">We may be subject to certain commissions as we might act as an Agent/ Registered Representative/ Broker for certain products recommended by us. You are under no obligation to act upon the recommendations/ action plan listed down in the financial plan.</div>
        </>
    )
}