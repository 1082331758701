import React from 'react'


export default function Targetrange(){
    return(
        <>
            <div className="targetbar_outer mt25">
                <div className="targetbar_title">
                    <div className="font14 fw500 color182">Desired target</div>
                    <div>
                        <div className="tragetvalue font13 fw700 colorf47">
                            <span className="orangeDot mr5" />10.2L
                        </div>
                    </div>
                </div>
                <div className="targetbar mt10">
                    <form className="target_range_bar">
                        <div class="form-group">
                            <input type="range" class="form-control-range" id="formControlRange" />
                        </div>
                    </form>
                    <div className="flex_center justify_center mt8">
                        <div className="font12 fw500 color9ba">0L</div>
                        <div className="font12 fw500 color9ba">50L</div>
                    </div>
                </div>
                <div className="targetvalue_outer mt25">
                    <div className="targetvalue_prnt">
                        <div className="valuecount valuecount_gold font16 fw600">8.61L</div>
                        <div className="font12 fw500 color626">Yearly ongoing</div>
                    </div>
                    <div className="targetvalue_prnt">
                        <div className="valuecount valuecount_green font16 fw600">9.5L</div>
                        <div className="font12 fw500 color626">Last year’s saving Ongoing + surplus</div>
                    </div>
                    <div className="targetvalue_prnt">
                        <div className="valuecount valuecount_blue font16 fw600">16.53L</div>
                        <div className="font12 fw500 color626">Recommended</div>
                    </div>
                </div>
                <div className="devider_line mt10" />
            </div>

            <div className="targetbar_outer mt25">
                <div className="targetbar_title">
                    <div className="font14 fw500 color182">Yearly investment increment</div>
                    <div>
                        <div className="tragetvalue font13 fw700">
                            10%
                        </div>
                    </div>
                </div>
                <div className="targetbar mt20">
                    <form className="target_range_bar">
                        <div class="form-group">
                            <input type="range" class="form-control-range" id="formControlRange" />
                        </div>
                    </form>
                    <div className="flex_center justify_center mt8">
                        <div className="font12 fw500 color9ba">0%</div>
                        <div className="font12 fw500 color9ba">100%</div>
                    </div>
                </div>
            </div>
            <div className="devider_line mt10" />
            
            <div className="targetbar_outer mt25">
                <div className="targetbar_title">
                    <div className="targetbar_title_left font14 fw500 color182">Active years & Retired years</div>
                    <div className="targetbar_title_right">
                        <div className="color353 font13 fw700">
                            32
                        </div>
                        <div className="ml10 mr10 color353">-</div>
                        <div className="tragetvalue font13 fw700 color353">
                            62
                        </div>
                        <div className="ml10 mr10 color353">-</div>
                        <div className="tragetvalue font13 fw700 color353">
                            84
                        </div>
                    </div>
                </div>
                <div className="targetbar mt20">
                    <form className="target_range_bar">
                        <div class="form-group">
                            <input type="range" class="form-control-range" id="formControlRange" />
                        </div>
                    </form>
                    <div className="flex_center justify_center mt8">
                        <div className="font12 fw500 color9ba">0</div>
                        <div className="font12 fw500 color9ba">100</div>
                    </div>
                </div>
            </div>

                
        </>
    )
}