import React from 'react'

export default function CashflowAnalysis(){
    return(
        <> 
            <div className="tabContent_title font12 fw500 color7B8 pb15">MyFinsmart / <span className="color263">Personal Information</span></div>

            <div className="font22 fw700 color0C4 pt30">Cashflow Analysis</div>

            <div className="font14 fw500 color182 mt20">
                The cash flow analysis is the projection of your Income, Expenses and Investments for the current year. A positive and consistent Cash flow is an essential to achieve your financial goals. The Savings that you make every year should be invested in such a way that you meet your financial goals.
            </div>

            <div className="inflowbox_outer mt30">
                <div className="inflowbox_outer_left">
                    <div className="inflowbox_title font12 fw600 color6d7">TOTAL INFLOW</div>
                    <div className="font12 fw500 color7B8 mt8">Your family’s total income including returns and inheritance.</div>
                    <div className="font24 fw500 color263 mt12">18.5L</div>
                </div>
                <div className="inflowbox_outer_right">
                    <div className="inflowdtl">
                        <div>
                            <div className="font16 fw500 color263">24.5L</div>
                            <div className="font13 color7B8 fw500">Self income</div>
                        </div>
                        <div>
                            <div className="font16 fw500 color263">10.5L</div>
                            <div className="font13 color7B8 fw500">Spouse income</div>
                        </div>
                        <div>
                            <div className="font16 fw500 color263">5L</div>
                            <div className="font13 color7B8 fw500">Rental income</div>
                        </div>
                        <div>
                            <div className="font16 fw500 color263">2.5L</div>
                            <div className="font13 color7B8 fw500">HUB income</div>
                        </div>
                        <div>
                            <div className="font16 fw500 color263">0.5L</div>
                            <div className="font13 color7B8 fw500">Other income</div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="inflowbox_outer mt15">
                <div className="inflowbox_outer_left">
                    <div className="inflowbox_title orangedot font12 fw600 color6d7">TOTAL OUTFLOW</div>
                    <div className="font12 fw500 color7B8 mt8">Your family’s total outflow including EMIs and Commitments.</div>
                    <div className="font24 fw500 color263 mt12">10.1L</div>
                </div>
                <div className="inflowbox_outer_right">
                    <div className="inflowdtl">
                        <div>
                            <div className="font16 fw500 color263">12.5L</div>
                            <div className="font13 color7B8 fw500">HouseHold</div>
                        </div>
                        <div>
                            <div className="font16 fw500 color263">5.2L</div>
                            <div className="font13 color7B8 fw500">Child Education</div>
                        </div>
                        <div>
                            <div className="font16 fw500 color263">2.9L</div>
                            <div className="font13 color7B8 fw500">Rental Paid</div>
                        </div>
                        <div>
                            <div className="font16 fw500 color263">1.5L</div>
                            <div className="font13 color7B8 fw500">EMI’s</div>
                        </div>
                        <div>
                            <div className="font16 fw500 color263">1.9L</div>
                            <div className="font13 color7B8 fw500">Insurance</div>
                        </div>
                        <div>
                            <div className="font16 fw500 color263">0.5L</div>
                            <div className="font13 color7B8 fw500">Other Expense</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="totalSaving_box mt15">
                <div>
                    <div class="inflowbox_title font12 fw600 color6d7">TOTAL INFLOW</div>
                    <div class="font24 fw500 color263 mt12">18.5L</div>
                </div>
                <div className="circleBtn_outer">
                    <div className="circleBtn">-</div>
                </div>
                <div>
                    <div class="inflowbox_title orangedot font12 fw600 color6d7">TOTAL INFLOW</div>
                    <div class="font24 fw500 color263 mt12">18.5L</div>
                </div>
                <div className="circleBtn_outer">
                    <div className="circleBtn">=</div>
                </div>
                <div>
                    <div class="inflowbox_title bluedot font12 fw600 color6d7">TOTAL SAVING</div>
                    <div class="font24 fw500 color263 mt12">18.5L</div>
                </div>
            </div>
            

        </>
    )
}