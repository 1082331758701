import React from 'react'
import '../css/processstep.css';

export default function Processstep(){
    return(
        <> 
            <div className="mob_left_right p40">
                <ul className="processstep_list">
                    <li>
                        <div className="processstep_list_left fw500 font14">
                            <span className="basicIcon mr15" />
                            Basic details
                        </div>
                        <div className="processstep_list_right">
                            <span className="edittab font12 fw500 mr8">Edit</span>
                            <span className="greentick"></span>
                        </div>
                    </li>
                    <li>
                        <div className="processstep_list_left fw500 font14">
                            <span className="protection mr15" />
                            Protection
                        </div>
                        <div className="processstep_list_right">
                            <span className="edittab font12 fw500 mr8">Edit</span>
                            <span className="greentick"></span>
                        </div>
                    </li>
                    <li>
                        <div className="processstep_list_left fw500 font14">
                            <span className="goal mr15" />
                            Goals
                        </div>
                        <div className="processstep_list_right">
                            <span className="edittab font12 fw500 mr8">Edit</span>
                            <span className="greentick"></span>
                        </div>
                    </li>
                    <li>
                        <div className="processstep_list_left fw500 font14">
                            <span className="cashflow mr15" />
                            Cashflow
                        </div>
                        <div className="processstep_list_right">
                            <span className="edittab font12 fw500 mr8">Edit</span>
                            <span className="greentick"></span>
                        </div>
                    </li>
                    <li className="active">
                        <div className="processstep_list_left fw500 font14">
                            <span className="rupee mr15" />
                            Investments
                        </div>
                        <div className="processstep_list_right">
                            <span className="edittab font12 fw500 mr8">Edit</span>
                            <span className="greentick"></span>
                        </div>
                    </li>
                </ul>


                <div className="font20 fw500 color212 mt100">
                    Your plan is ready! Lets start tweaking it now.
                </div>
                <div className="mt8 font16 fw500 color495">Appreciate your patience on this input process. Thank you.</div>
                <button type="button" class="btn btn-primary mt25">Get Started</button>

            </div>
        </>
    )
}