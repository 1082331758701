import React from 'react'
import Targetrange from '../components/targetrange'
import Dashboardgoals from '../components/dashboardgoals'
import Dashboardcashflow from '../components/dashboardcashflow'
import Dashboardinvestments from '../components/dashboardinvestments'
// import Dashboardfamily from '../components/dashboardfamilynew'
import Dashboardfamilynew from '../components/dashboardfamilynew'

export default function Dashboardleft(){
    return(
        <> 
            <div className="dashboard_left pb20">
                <div className="dashboard_left_title font16 fw500 color182">Naveen financial input</div>
                <div className="categoryTabs_outer mt20">
                    <li>
                        <div className="categoryTab font12 fw500 color353"><span className="risk mr5" /> Moderate risk</div>
                    </li>
                    <li>
                        <div className="categoryTab font12 fw500 color353"><span className="protection_gray mr5" /> Insured</div>
                    </li>
                    <li>
                        <div className="categoryTab font12 fw500 color353"><span className="family_icon mr5" /> Family of 5</div>
                    </li>
                    <li>
                        <div className="categoryTab font12 fw500 color353"><span className="home_icon mr5" /> Home loan</div>
                    </li>
                    <li>
                        <div className="categoryTab font12 fw500 color353">Return assumption <span className="font12 fw600 color06d ml5">8.3%</span></div>
                    </li>
                </div>
                {/* <div className="db_maintab_wpr mt25">
                    <ul className="font13">
                        <li className="act">Plan</li>
                        <li>Goals</li>
                        <li>Cashflow</li>
                        <li>Investments</li>
                        <li>Family</li>
                    </ul>
                </div> */}

                <div className="db_maintab_outer mt25">
                    <div className="db_maintab_wpr">
                        <nav className="font13">
                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                <a class="nav-item nav-link active" id="nav-plan-tab" data-toggle="tab" href="#nav-plan" role="tab" aria-controls="nav-plan" aria-selected="true">Plan</a>
                                <a class="nav-item nav-link" id="nav-goal-tab" data-toggle="tab" href="#nav-goal" role="tab" aria-controls="nav-goal" aria-selected="false">Goals</a>
                                <a class="nav-item nav-link" id="nav-cashflow-tab" data-toggle="tab" href="#nav-cashflow" role="tab" aria-controls="nav-cashflow" aria-selected="false">Cashflow</a>
                                <a class="nav-item nav-link" id="nav-investments-tab" data-toggle="tab" href="#nav-investments" role="tab" aria-controls="nav-investments" aria-selected="false">Investments</a>
                                <a class="nav-item nav-link" id="nav-family-tab" data-toggle="tab" href="#nav-family" role="tab" aria-controls="nav-family" aria-selected="false">Family</a>
                            </div>
                        </nav>
                    </div>
                    <div class="tab-content" id="nav-tabContent">
                    <div class="tab-pane fade show active" id="nav-plan" role="tabpanel" aria-labelledby="nav-plan-tab">
                        <Targetrange />
                    </div>
                    <div class="tab-pane fade" id="nav-goal" role="tabpanel" aria-labelledby="nav-goal-tab">
                        <Dashboardgoals />
                    </div>
                    <div class="tab-pane fade" id="nav-cashflow" role="tabpanel" aria-labelledby="nav-cashflow-tab">
                        <Dashboardcashflow />
                    </div>
                    <div class="tab-pane fade" id="nav-investments" role="tabpanel" aria-labelledby="nav-investments-tab">
                        <Dashboardinvestments />
                    </div>
                    <div class="tab-pane fade" id="nav-family" role="tabpanel" aria-labelledby="nav-family-tab">
                        {/* <Dashboardfamily /> */}
                        <Dashboardfamilynew />
                    </div>
                    </div>
                </div>
                
                
                


            </div>
        </>
    )
}