import React from 'react'


export default function Dashboardinvestments(){
    return(
        <>  
            <div className="db_investments_outer">
                <div className="db_investments_title">
                    <div className="font14 fw500 color182">Total investments</div>
                    <div className="font18 fw500 color182">1.83Cr</div>
                </div>
            </div>

            <div className="db_cashflow_outer pt20 pl30 pr30">
                <ul class="nav nav-pills mb-3 font12 fw500 color353" id="pills-tab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="pills-currentallocation-tab" data-toggle="pill" href="#pills-currentallocation" role="tab" aria-controls="pills-currentallocation" aria-selected="true">Current allocation</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="pills-suggestallocation-tab" data-toggle="pill" href="#pills-suggestallocation" role="tab" aria-controls="pills-suggestallocation" aria-selected="false">Suggested allocation</a>
                    </li>
                </ul>
                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-currentallocation" role="tabpanel" aria-labelledby="pills-currentallocation-tab">
                        <div className="currentallocation_bar">
                            <progress value="75" max="100"></progress>
                        </div>

                        <div className="cashflow_inflow_wpr cashflow_outflow_wpr">
                            <div className="earnincome_outer mt20">
                                <div className="earnincome_title font11 fw600 color6d7 lightgreen_dot">
                                    EQUITY <span className="color182 ml8">52.5L</span>
                                </div>
                                <div className="incomeinfo mt15">
                                    <div>
                                        <div className="font12 fw500 color6d7">Direct Stocks</div>
                                        <div className="font16 fw500 color162">20.5L</div>
                                    </div>
                                    <div>
                                        <div className="font12 fw500 color6d7">Mutual Funds</div>
                                        <div className="font16 fw500 color162">22.8L</div>
                                    </div>
                                </div>
                            </div>
                            <div className="earnincome_outer mt20">
                                <div className="earnincome_title justify_center font11 fw600 color6d7 mediumgreen_dot">
                                    <div>
                                        DEBT <span className="color182 ml8">91.7L</span>
                                    </div>
                                </div>
                                <div className="incomeinfo mt15">
                                    <div>
                                        <div className="font12 fw500 color6d7">Fixed Deposits</div>
                                        <div className="font16 fw500 color162">52.1L</div>
                                    </div>
                                    <div>
                                        <div className="font12 fw500 color6d7">Bonds</div>
                                        <div className="font16 fw500 color162">23.2L</div>
                                    </div>
                                    <div>
                                        <div className="font12 fw500 color6d7">EPF</div>
                                        <div className="font16 fw500 color162">10.9L</div>
                                    </div>
                                    <div>
                                        <div className="font12 fw500 color6d7">PPF</div>
                                        <div className="font16 fw500 color162">5.5L</div>
                                    </div>
                                </div>
                            </div>
                            <div className="earnincome_outer mt20">
                                <div className="earnincome_title justify_center font11 fw600 color6d7 darkgreen_dot">
                                    <div>
                                        OTHERS <span className="color182 ml8">39.1L</span>
                                    </div>
                                </div>
                                <div className="incomeinfo mt15">
                                    <div>
                                        <div className="font12 fw500 color6d7">Gold</div>
                                        <div className="font16 fw500 color162">20.2L</div>
                                    </div>
                                    <div>
                                        <div className="font12 fw500 color6d7">Real Estate</div>
                                        <div className="font16 fw500 color162">18.8L</div>
                                    </div>
                                </div>
                            </div>
                    </div>


                    </div>
                    <div class="tab-pane fade" id="pills-suggestallocation" role="tabpanel" aria-labelledby="pills-suggestallocation-tab">
                        Suggested allocation
                    </div>
                
                </div>
            </div>

        </>
    )
}