import React from 'react'

export default function LoanManagement(){
    return(
        <> 
            <div className="tabContent_title font12 fw500 color7B8 pb15">MyFinsmart / <span className="color263">Loan Management</span></div>

            <div className="font22 fw700 color0C4 pt30">Loan Management</div>

            <div className="font14 fw500 color182 mt20">It is important to maintain an emergency fund to meet the expenses arising out of any unforeseen events like unexpected medical expenses . Emergency funds should be invested in short-term investments such as liquid funds, Bank Flexi- Deposits or a combination of such ultra liquid instruments</div>

            <div className="loanBox_outer mt25 p25">
                <div className="loandtl">
                    <div className="font13 fw500 color6d7">Toal Loan Amount</div>
                    <div className="font24 fw500 color182">18.3Cr</div>
                </div>
                <div className="loandtl">
                    <div className="font13 fw500 color6d7">Toal Loan Amount</div>
                    <div className="font24 fw500 color182">18.3Cr</div>
                </div>
                <div className="loandtl">
                    <div className="font13 fw500 color6d7">Toal Loan Amount</div>
                    <div className="font24 fw500 color182">18.3Cr</div>
                </div>
            </div>

            <div className="font14 fw500 color182 mt30">Loan Category breakup</div>

            <div className="loanBox_outer mt20 p25">
                <div className="loandtl">
                    <div className="font16 fw600 color263">Home Loan</div>
                    <div className="font11 fw500 color7b8">Lender - Bank of Baroda</div>
                </div>
                <div className="loandtl">
                    <div className="font12 fw500 color6d7">Loan Amount</div>
                    <div className="font16 fw500 color162">80.5L</div>
                </div>
                <div className="loandtl">
                    <div className="font12 fw500 color6d7">EMI’ Yearly</div>
                    <div className="font16 fw500 color162">72,000</div>
                </div>
                <div className="loandtl">
                    <div className="font12 fw500 color6d7">Remaining Amount</div>
                    <div className="font16 fw500 color162">50L</div>
                </div>
            </div>

            <div className="loanBox_outer mt20 p25">
                <div className="loandtl">
                    <div className="font16 fw600 color263">Home Loan</div>
                    <div className="font11 fw500 color7b8">Lender - Bank of Baroda</div>
                </div>
                <div className="loandtl">
                    <div className="font12 fw500 color6d7">Loan Amount</div>
                    <div className="font16 fw500 color162">80.5L</div>
                </div>
                <div className="loandtl">
                    <div className="font12 fw500 color6d7">EMI’ Yearly</div>
                    <div className="font16 fw500 color162">72,000</div>
                </div>
                <div className="loandtl">
                    <div className="font12 fw500 color6d7">Remaining Amount</div>
                    <div className="font16 fw500 color162">50L</div>
                </div>
            </div>

            <div className="loaninfo font13 mt60">
                Your Total Yearly EMIs are <b>84T</b>
            </div>
            <div className="loaninfo font13 mt8">
                Your EMIs are <b>15 %</b> of your Income.
            </div>
            <div className="loaninfo font13 mt8">
                A % higher than <b>60%</b> suggests that you would find it difficult to get any further financing.
            </div>


        </>
    )
}