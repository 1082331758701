import React from 'react'


export default function Dashboardfamilynew(){
    return(
        <>  
            <div className="familycard p25">
                <div className="familycard_title">
                    <div className="color182 font14 fw600 familycard_title_left">Family members</div>
                    <div className="title_viewmore"><a href="#" className="font12 fw600 color0d6">View more <span className="bluerightarrow"></span></a> </div>
                </div>
                <div className="familycard_content mt20">
                    <div className="familycard_content_left">
                        <div className="fw500 colora5b font12"><span className="familyicon mr5" /> Members</div>
                        <div className="fw600 color162 font18">06</div>
                    </div>
                    <div className="familycard_content_mdl">
                        <div className="fw500 colora5b font12">Total Adults</div>
                        <div className="fw600 color162 font18">04</div>
                    </div>
                    <div className="familycard_content_right">
                        <div className="fw500 colora5b font12">Total Kids</div>
                        <div className="fw600 color162 font18">02</div>
                    </div>
                </div>
            </div>
            <div className="familycard graybg p25">
                <div className="familycard_title">
                    <div className="color182 font14 fw600 familycard_title_left"><span className="insuranceicon mr5" /> Insurance</div>
                    <div className="title_viewmore"><a href="#" className="font12 fw600 color0d6">View more <span className="bluerightarrow"></span></a> </div>
                </div>
                <div className="familycard_content mt20">
                    <div className="familycard_content_left">
                        <div className="fw500 colora5b font12">Members</div>
                        <div className="fw600 color162 font18">2Cr</div>
                    </div>
                    <div className="familycard_content_mdl">
                        <div className="fw500 colora5b font12">Total Adults</div>
                        <div className="fw600 color162 font18">1Cr</div>
                    </div>
                    <div className="familycard_content_right">
                        <div className="fw500 colora5b font12">Total Kids</div>
                        <div className="fw600 color162 font18">2Cr</div>
                    </div>
                </div>
            </div>
            <div className="familycard  p25">
                <div className="familycard_title">
                    <div className="color182 font14 fw600 familycard_title_left"><span className="fundicon mr5" /> Emergency Funds</div>
                    <div className="title_viewmore"><a href="#" className="font12 fw600 color0d6">View more <span className="bluerightarrow"></span></a> </div>
                </div>
                <div className="familycard_content mt20">
                    <div className="familycard_content_left">
                        <div className="fw500 colora5b font12">Total Emergency fund</div>
                        <div className="fw600 color162 font18">32L</div>
                    </div>
                </div>
            </div>

        </>
    )
}