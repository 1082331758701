import React from 'react'

export default function PersonalInformation(){
    return(
        <> 
            <div className="tabContent_title font12 fw500 color7B8 pb15">MyFinsmart / <span className="color263">Personal Information</span></div>

            <div className="font22 fw700 color0C4 pt30">Personal Information</div>
            
            <div className="personInfo_outer mt25">
                <div className="personInfo_title font16 fw600 color263">Ranjan kumar <span className="color7B8">(you)</span></div>
                <div className="infotab_outer mt10">
                    <span className="infotab font12 fw500 color263">42 yrs</span> <span className="infotab font12 fw500 color263">17/03/1998</span> <span className="infotab font12 fw500 color263">ranjankumar1998@gmail.com</span> <span className="infotab font12 fw500 color263">+91 9644981065</span>
                </div>
            </div>

            <div className="person_info_tbl mt30">
                <table class="table font12">
                    <thead>
                        <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Date of birth</th>
                        <th scope="col">Age</th>
                        <th scope="col">Relationship</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">Shivaranjitha</th>
                            <td>01/01//1982</td>
                            <td>40</td>
                            <td>Spouse</td>
                        </tr>
                        <tr>
                            <th scope="row">Shivaranjitha</th>
                            <td>01/01//1982</td>
                            <td>40</td>
                            <td>Spouse</td>
                        </tr>
                        <tr>
                            <th scope="row">Shivaranjitha</th>
                            <td>01/01//1982</td>
                            <td>40</td>
                            <td>Spouse</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="font14 fw500 color182 mt45">
                As per our life stages Wealth Creation can be divided in various phases,
            </div>

            <ul className="variousList mt15 font14 color182 fw500">
                <li>Foundation Phase - Age 20 to 30</li>
                <li>Accumulation Phase Age 30 to 55</li>
                <li>Preservation Phase Age 55 to 75</li>
                <li>Distribution Phase - Age 75+</li>
            </ul>

        </>
    )
}