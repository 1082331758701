import React from 'react'
import Dashboardfamilycard from '../components/dashboardfamilycard'

export default function Dashboardright(){
    return(
        <> 
            <div className="dashboard_right">
                <div className="graphvalue">
                    <div className="graphvalue_left">
                        <div className="graphvalue_count font22 fw500">
                            2.45Cr
                        </div>
                        <div className="ml15 font12 fw500 color6d7">
                            Retirement corpus at 2044 with your desired yearly target(10.2L) with yearly increase.
                        </div>
                    </div>
                    <div className="graphvalue_right">
                        <div className="graphvalue_count font22 fw500">
                            65%
                        </div>
                        <div className="ml15 font12 fw500 color6d7">
                            On avg. you may achieve only 65% of all your life goals
                        </div>
                    </div>
                </div>


                <div className="graphtabs_outer mt25">
                    <div className="graphtabs">
                        <nav className="font12">
                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                <a class="nav-item nav-link active" id="nav-activeyears-tab" data-toggle="tab" href="#nav-activeyears" role="tab" aria-controls="nav-activeyears" aria-selected="true">Family Members</a>
                                <a class="nav-item nav-link" id="nav-retiredyears-tab" data-toggle="tab" href="#nav-retiredyears" role="tab" aria-controls="nav-retiredyears" aria-selected="false">Insurance</a>
                                <a class="nav-item nav-link" id="nav-tabularview-tab" data-toggle="tab" href="#nav-tabularview" role="tab" aria-controls="nav-tabularview" aria-selected="false">Emergency funds</a>
                            </div>
                        </nav>
                    </div>
                    <div class="tab-content" id="nav-tabContent">
                    <div class="tab-pane fade show active" id="nav-activeyears" role="tabpanel" aria-labelledby="nav-activeyears-tab">
                    <Dashboardfamilycard />
                    </div>
                    <div class="tab-pane fade" id="nav-retiredyears" role="tabpanel" aria-labelledby="nav-retiredyears-tab">
                        <div className="graph_container">&nbsp;</div>

                        <div className="graph_list_outer">
                            <ul className="graph_detail_list">
                                <li>
                                    <span className="dashline mr8" />
                                    Desired target
                                </li>
                                <li>
                                    <label className="listcheck_outer">
                                        <span className="dashline bgcolorbb8 mr8" />
                                        Yearly ongoing
                                        <span className="listcheck ml10">
                                            <input type="checkbox" />
                                            <span className="listcheck_box" />
                                        </span>
                                    </label>
                                </li>
                                <li>
                                    <label className="listcheck_outer">
                                        <span className="dashline bgcolor0dc mr8" />
                                        Recommended target
                                        <span className="listcheck ml10">
                                            <input type="checkbox" />
                                            <span className="listcheck_box" />
                                        </span>
                                    </label>
                                </li>
                                <li>
                                    <label className="listcheck_outer">
                                        <span className="dashline bgcolor088 mr8" />
                                        Last year’s savings
                                        <span className="listcheck ml10">
                                            <input type="checkbox" />
                                            <span className="listcheck_box" />
                                        </span>
                                    </label>
                                </li>
                                <li>
                                    <span className="rupee_greenbox mr8" />
                                    Total savings
                                </li>
                                <li>
                                    <span className="flag_bluebox mr8" />
                                    Retirement goal
                                </li>
                                <li>
                                    <span className="resetofgoal_box mr8" />
                                    Rest of the goals
                                </li>
                                <li>
                                    <label className="listcheck_outer">
                                        <span className="dashline bgcolor8c7 mr8" />
                                        Predicted income
                                        <span className="listcheck ml10">
                                            <input type="checkbox" />
                                            <span className="listcheck_box" />
                                        </span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="nav-tabularview" role="tabpanel" aria-labelledby="nav-tabularview-tab">
                        <div className="emergencyfund_card pl30 pr30">
                                <div className="emergencyfund_title_flex mt30">
                                    <div className="coveragetitle">
                                        <div className="svgprogressbar">
                                            <div class="progress-bar">
                                                <progress value="75" min="0" max="100">75%</progress>
                                            </div>
                                        </div>
                                        <div className="pl15">
                                            <div className="font14 fw500 color6d7">Emergency funds</div>
                                            <div className="font21 fw700 color182">33L</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="font14 fw500 color6d7">Total Goal</div>
                                        <div className="font21 fw700 color182">44L</div>
                                    </div>
                                </div>
                                <div className="coveragechart_card mt20">
                                    <div className="coveragechart_card_hdr font12 fw500 color949 pb10">
                                        <div className="coveragechart_card_hdr_inner">Total Funds</div>
                                        <div className="coveragechart_card_hdr_inner">Liquid funds</div>
                                        <div className="coveragechart_card_hdr_inner">Short term FD</div>
                                        <div className="coveragechart_card_hdr_inner">Cash in bank</div>
                                        <div className="coveragechart_card_hdr_inner">Add more</div>
                                    </div>
                                    <div className="coveragechart_card_row font14 fw600 color263">
                                        <div className="coveragechart_card_row_inner">33L</div>
                                        <div className="coveragechart_card_row_inner">09L </div>
                                        <div className="coveragechart_card_row_inner">16L</div>
                                        <div className="coveragechart_card_row_inner">08L</div>
                                        <div className="coveragechart_card_row_inner">
                                            <span className="color0d6 font12">Add Funds <span class="bluerightarrow ml5"></span></span>
                                        </div>
                                    </div>
                                </div>


                                <span className="imgbanner"></span>

                        </div>

                        
                    </div>
                    </div>
                </div>

            </div>
        </>
    )
}