import React from 'react'
// import Homeform from '../components/homeform'
// import Protectionform from '../components/protectionform'
// import PercentageLoanform from '../components/percentageloanform'
// import Desireinvestment from '../components/desireinvestment'
import Processstep from '../components/processstep'


export default function Home() {
    return (
        <>
           
            <div className="innercontainer mb40">
                <div className="mainwrapper">
                    <div className="form_title">
                        <div className="mt30 font20 fw600">First things first!</div>
                        <div className="font15 color495">Questions to know basic details</div>
                    </div>

                    <div className="row formWpr mt25">
                        <div className="col-lg-8 formouter">
                            <ul className="form_swipe_tab goldtext font12 fw600">
                                <li className="backto font12 fw500">Back to 1</li>
                                <li className="act">2</li>
                                <li>3</li>
                            </ul>

                            <div className="formtitle flex_center justify_center">
                                <div>
                                    <div className="font20 fw600">First things first!</div>
                                    <div className="font15 color495 ">Questions to know basic details</div>
                                </div>
                                <div>
                                    <button className="bluebtn font13 fw600" disabled>Save 
                                    <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" stroke-width="1.30435" stroke-miterlimit="10"/>
                                        </svg></span></button>
                                </div>
                            </div>

                            {/* <Homeform /> */}
                            {/* <Protectionform /> */}
                            {/* <PercentageLoanform /> */}
                            {/* <Desireinvestment /> */}
                            <Processstep />

                        </div>
                        <div className="col-lg-4 qouteouter d-none d-md-block">
                            <div className="qoutetext font18 fw500 color212 pl45 pr45">
                                <div className="qoutesign">
                                    &#8220;
                                </div>
                                Don’t worry about enclosing PAN details to finnovate. Your details will be secure and encrypted.
                            </div>


                            <div className="qouteimg" />
                        </div>
                    </div>

                    <div className="saveBtn d-block d-sm-none">
                        <button type="button" class="btn btn-primary">Save and Continue <span class="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" stroke-width="1.30435" stroke-miterlimit="10"></path></svg></span></button>
                    </div>


                    <div className="saveBtn backsaveBtn d-block d-sm-none">
                        <button type="button" class="btn btn-primary">Back </button>
                        <button type="button" class="btn btn-primary">Back </button>
                    </div>

                </div>
            </div>
        </>
    )
}