import React from 'react'


export default function Dashboardfamilycard(){
    return(
        <>  
            <div className="familycard_container">
                <div className="familycardtabs">
                    <div className="db_cashflow_outer pt20 pl30 pr30">
                            <ul class="nav nav-pills mb-3 font12 fw500 color353" id="pills-tab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" id="pills-Members-tab" data-toggle="pill" href="#pills-Members" role="tab" aria-controls="pills-Members" aria-selected="true">Members</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="pills-person-tab" data-toggle="pill" href="#pills-person" role="tab" aria-controls="pills-person" aria-selected="false">Ranjan</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="pills-spouse-tab" data-toggle="pill" href="#pills-spouse" role="tab" aria-controls="pills-spouse" aria-selected="false">spouse</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="pills-kids-tab" data-toggle="pill" href="#pills-kids" role="tab" aria-controls="pills-kids" aria-selected="false">Kids</a>
                                </li>
                            </ul>
                            <div class="tab-content pt10" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-Members" role="tabpanel" aria-labelledby="pills-Members-tab">
                                <div className="membercharthdr font12 fw500 color949">
                                    <div className="membercharthdr_inner">Member</div>
                                    <div className="membercharthdr_inner">Relation</div>
                                    <div className="membercharthdr_inner">Age</div>
                                    <div className="membercharthdr_inner">Net worth</div>
                                    <div className="membercharthdr_inner">Income</div>
                                    <div className="membercharthdr_inner">Goals</div>
                                </div>
                                <div className="mt15 font10 fw500 color9ba memberchart_title pb5">PLANNERS</div>
                                <div className="membercharthdr font12 fw500 color182 pt15">
                                    <div className="membercharthdr_inner">Ranjan Kumar <span className="bluebadge ml5" /></div>
                                    <div className="membercharthdr_inner">Planner</div>
                                    <div className="membercharthdr_inner">42 yrs</div>
                                    <div className="membercharthdr_inner">0.8Cr</div>
                                    <div className="membercharthdr_inner">52%</div>
                                    <div className="membercharthdr_inner">02</div>
                                </div>
                                <div className="membercharthdr font12 fw500 color182 pt15">
                                    <div className="membercharthdr_inner">Shivaranjitha <span className="goldbadge ml5" /></div>
                                    <div className="membercharthdr_inner">Spouse</div>
                                    <div className="membercharthdr_inner">41 yrs</div>
                                    <div className="membercharthdr_inner">0.6Cr</div>
                                    <div className="membercharthdr_inner">42%</div>
                                    <div className="membercharthdr_inner">03</div>
                                </div>
                                <div className="mt15 font10 fw500 color9ba memberchart_title pb5">KIDS</div>
                                <div className="membercharthdr font12 fw500 color182 pt15">
                                    <div className="membercharthdr_inner">Pinku </div>
                                    <div className="membercharthdr_inner">Kid</div>
                                    <div className="membercharthdr_inner">12 yrs</div>
                                    <div className="membercharthdr_inner">--</div>
                                    <div className="membercharthdr_inner">--</div>
                                    <div className="membercharthdr_inner">03</div>
                                </div>
                                <div className="membercharthdr font12 fw500 color182 pt15">
                                    <div className="membercharthdr_inner">Rinku </div>
                                    <div className="membercharthdr_inner">Kid</div>
                                    <div className="membercharthdr_inner">12 yrs</div>
                                    <div className="membercharthdr_inner">--</div>
                                    <div className="membercharthdr_inner">--</div>
                                    <div className="membercharthdr_inner">03</div>
                                </div>
                                <div className="mt15 font10 fw500 color9ba memberchart_title pb5">DEPENDENTS</div>
                                <div className="membercharthdr font12 fw500 color182 pt15">
                                    <div className="membercharthdr_inner">Shiva Ranjan </div>
                                    <div className="membercharthdr_inner">Father</div>
                                    <div className="membercharthdr_inner">72 yrs</div>
                                    <div className="membercharthdr_inner">0.8Cr</div>
                                    <div className="membercharthdr_inner">--</div>
                                    <div className="membercharthdr_inner">01</div>
                                </div>
                                <div className="membercharthdr font12 fw500 color182 pt15">
                                    <div className="membercharthdr_inner">Neerja </div>
                                    <div className="membercharthdr_inner">Mother</div>
                                    <div className="membercharthdr_inner">71 yrs</div>
                                    <div className="membercharthdr_inner">0.6Cr</div>
                                    <div className="membercharthdr_inner">--</div>
                                    <div className="membercharthdr_inner">01</div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-person" role="tabpanel" aria-labelledby="pills-person-tab">
                                <div className="coveragetitle">
                                    <div className="coveragetitle_icon">
                                        <span className="coverageicon2" />
                                    </div>
                                    <div className="pl15">
                                        <div className="font14 fw500 color6d7">Health Coverage</div>
                                        <div className="font21 fw700 color182">60L</div>
                                    </div>
                                </div>
                                <div className="coveragechart_card mt20">
                                    <div className="coveragechart_card_hdr font12 fw500 color949 pb10">
                                        <div className="coveragechart_card_hdr_inner">Protection</div>
                                        <div className="coveragechart_card_hdr_inner">Sugg.(Income)</div>
                                        <div className="coveragechart_card_hdr_inner">Sugg.(Goals)</div>
                                        <div className="coveragechart_card_hdr_inner">Sugg.(Expense)</div>
                                        <div className="coveragechart_card_hdr_inner">Recommendation</div>
                                    </div>
                                    <div className="coveragechart_card_row font14 fw600 color263">
                                        <div className="coveragechart_card_row_inner colorfb9">80%</div>
                                        <div className="coveragechart_card_row_inner">68L <span className="greentick ml5" /></div>
                                        <div className="coveragechart_card_row_inner">64L</div>
                                        <div className="coveragechart_card_row_inner">73L</div>
                                        <div className="coveragechart_card_row_inner">
                                            <span className="color0d6">View Suggestion <span class="bluerightarrow ml5"></span></span>
                                        </div>
                                    </div>
                                </div>

                                <div className="coveragetitle mt35">
                                    <div className="coveragetitle_icon coveragetitle_icon_blue">
                                        <span className="coverageicon1" />
                                    </div>
                                    <div className="pl15">
                                        <div className="font14 fw500 color6d7">Term Coverage</div>
                                        <div className="font21 fw700 color182">1.25Cr</div>
                                    </div>
                                </div>
                                <div className="coveragechart_card mt20">
                                    <div className="coveragechart_card_hdr font12 fw500 color949 pb10">
                                        <div className="coveragechart_card_hdr_inner">Protection</div>
                                        <div className="coveragechart_card_hdr_inner">Sugg.(Income)</div>
                                        <div className="coveragechart_card_hdr_inner">Sugg.(Goals)</div>
                                        <div className="coveragechart_card_hdr_inner">Sugg.(Expense)</div>
                                        <div className="coveragechart_card_hdr_inner">Recommendation</div>
                                    </div>
                                    <div className="coveragechart_card_row font14 fw600 color263">
                                        <div className="coveragechart_card_row_inner colorfb9">80%</div>
                                        <div className="coveragechart_card_row_inner">68L <span className="greentick ml5" /></div>
                                        <div className="coveragechart_card_row_inner">64L</div>
                                        <div className="coveragechart_card_row_inner">73L</div>
                                        <div className="coveragechart_card_row_inner">
                                            <span className="color0d6">View Suggestion <span class="bluerightarrow ml5"></span></span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="tab-pane fade" id="pills-spouse" role="tabpanel" aria-labelledby="pills-spouse-tab">
                                Spouse    
                            </div>
                            <div class="tab-pane fade" id="pills-kids" role="tabpanel" aria-labelledby="pills-kids-tab">
                                kids
                            </div>
                            </div>
                        </div>
                    </div>
            </div>

        </>
    )
}